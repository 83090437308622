/** @jsx jsx */
import { jsx, Flex, Box, Container, Themed } from "theme-ui";
import React, { useContext, useEffect } from "react";
import PageTitle from "../components/PageTitle";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { LocalizationContext } from "../context/LocalizationContext";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "../components/Link";
import { useSitePages } from "../hooks/useSitePages";
import { getLocalizedUrlAndTitleFromSlug } from "../utils/utils";
import { useStaticQuery, graphql } from "gatsby";
import { send404Event } from "../utils/analytics";

const PointerIcon = () => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    sx={{
      display: "block",
      width: 40,
      height: 40,
      color: "text",
      mb: "4px",
      ml: 2,
    }}
  >
    <path
      fill="currentColor"
      d="M29.16 4l.373.38 7.699 7.413-1.388 1.44-5.591-5.383c.24 11.158-5.117 18.646-14.162 23.042-3.061 1.488-6.331 2.505-9.6 3.141a39.572 39.572 0 01-3.162.489l-.477.05c-.15.013-.29.026-.42.036l-.334.022L2 32.633l.293-.02c.205-.016.479-.042.814-.08a37.58 37.58 0 003.002-.463c3.11-.605 6.217-1.572 9.108-2.977 8.432-4.098 13.32-10.962 13.031-21.394l-5.63 5.528-1.4-1.427L29.158 4z"
    />
  </Box>
);

const F = () => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 718 1437"
    sx={{ display: "block" }}
  >
    <path
      d="M717.228.184V170.11c-61.753 0-118.745 25.785-164.449 69.684a296.675 296.675 0 00-30.759 34.75c-43.061 56.885-69.61 133.39-69.61 217.762H264.829c0-102.875 29.163-198.46 79.316-278.068a486.009 486.009 0 0162.023-79.04C486.802 51.829 596.593.184 717.228.184m.001 472.261V642.37c-145.651 0-264.82 143.444-264.82 322.2v472.261h-187.58V964.567c0-176.546-119.169-322.2-264.819-322.2V472.445c145.651 0 275.853 75.032 359.713 191.994 81.653-116.962 211.855-191.994 357.506-191.994"
      fill="#D1D1D1"
    />
  </Box>
);

const NotFoundPage = () => {
  const {
    contentfulAsset: { gatsbyImageData },
  } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "404" }) {
        gatsbyImageData(layout: FULL_WIDTH, quality: 85, aspectRatio: 3)
      }
    }
  `);
  const image = gatsbyImageData;
  const { language, translate, setLanguage } = useContext(LocalizationContext);
  const pages = useSitePages();

  const { slug } = getLocalizedUrlAndTitleFromSlug("/", language, pages);

  useEffect(() => {
    send404Event();
  }, []);

  useEffect(() => {
    const userPreferredLanguage = localStorage.getItem("userPreferredLanguage");
    if (userPreferredLanguage && userPreferredLanguage !== language) {
      setLanguage(userPreferredLanguage);
    }
  });

  return (
    <Layout>
      <SEO title={translate("404.seotitle")} />
      <Box
        sx={{ position: "relative", bg: "muted", flex: 1, overflow: "hidden" }}
      >
        <Container sx={{ position: "relative", py: [2, 3], zIndex: 1 }}>
          <Flex sx={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
            <Themed.p sx={{ m: 0 }}>
              <Themed.strong>{translate("404.trySearch")}</Themed.strong>
            </Themed.p>
            <PointerIcon />
          </Flex>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: [160, 7],
              right: 4,
              zIndex: 0,
              maxWidth: "60vw",
              opacity: 0.25,
            }}
          >
            <F />
          </Box>
        </Container>
        <Container variant="narrow" sx={{ position: "relative", zIndex: 2 }}>
          {image && <GatsbyImage image={image} alt="" />}
          <PageTitle>{translate("404.title")}</PageTitle>
          <Themed.p>{translate("404.description")}</Themed.p>
          <Link target={slug} variant="buttons.plain" showArrow>
            {translate("404.frontpage")}
          </Link>
        </Container>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;
